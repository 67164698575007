<template>
  <section id="content" ref="contractsContent">
    <PageTitle :title="$t('eurofiscalis_contracts.title')"></PageTitle>
    <div class="wrapper wrapper-content animated fadeInRight">

      <div class="row generate-success">
        <div class="col-lg-12">
          <div class="ibox">
            <div class="ibox-title">
              <h4>France</h4>
              <div class="ibox-tools">
                <a class="collapse-link">
                    <i class="fa fa-chevron-up"></i>
                </a>
              </div>
            </div>
            <div class="ibox-content ibox-parent gray-bg">
              <div class="row no-margins">
                <div class="col-lg-6 p-xxs">
                  <div class="ibox">
                    <div class="ibox-title">
                      <h4>Vos informations</h4>
                      <div class="ibox-tools">
                        <a class="collapse-link">
                            <i class="fa fa-chevron-up"></i>
                        </a>
                      </div>
                    </div>
                    <div class="ibox-content">
                      <ul class="list-group clear-list">
                        <li class="list-group-item fist-item">
                            Date de debut d'activite: 30/01/2019
                        </li>
                        <li class="list-group-item">
                            N TVA: FR1039580329580
                        </li>
                        <li class="list-group-item">
                            N SIREN: F0987097
                        </li>
                        <li class="list-group-item">
                            Paiement: Prelevement compte client
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 p-xxs">
                  <div class="ibox">
                    <div class="ibox-title">
                      <h4>Dossier Mere</h4>
                      <div class="ibox-tools">
                        <a class="collapse-link">
                            <i class="fa fa-chevron-up"></i>
                        </a>
                      </div>
                    </div>
                    <div class="ibox-content">
                      <b-table 
                          outlined striped
                          :items="motherFolderProvider"
                          :fields="motherFolderFields"
                          ref="motherFolder">
                      </b-table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row no-margins">
                <div class="col-lg-12 p-xxs">
                  <div class="ibox">
                    <div class="ibox-title">
                      <h4>Vos déclarations et documents</h4>
                      <div class="ibox-tools">
                        <a class="collapse-link">
                            <i class="fa fa-chevron-up"></i>
                        </a>
                      </div>
                    </div>
                    <div class="ibox-content">
                      <ul class="list-group clear-list">
                        <li class="list-group-item fist-item">
                            Date de debut d'activite: 30/01/2019
                        </li>
                        <li class="list-group-item">
                            N TVA: FR1039580329580
                        </li>
                        <li class="list-group-item">
                            N SIREN: F0987097
                        </li>
                        <li class="list-group-item">
                            Paiement: Prelevement compte client
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import PageTitle from '../../panels/PageTitle.vue';
import Messages from '../../panels/Messages.vue';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';
import { enableIbox, disableIbox} from '../../../theme/inspinia-jquery'; 

@Component({
  components: {
    PageTitle
  }
})
export default class Contracts extends mixins(GenericPage) {

  mounted() {
    enableIbox(this.$refs.contractsContent as HTMLElement);
  }

  beforeDestroy() {
    disableIbox(this.$refs.contractsContent as HTMLElement);
  }

  motherFolderFields = [
      {
        key: "select",
        label: ""
      },
      {
        key: "fileName"
      },
      {
        key: "options"
      }
    ];

  motherFolderProvider(ctx:any) {
    return [];
  }

}
</script>